





































import { Component, Ref, Vue } from "vue-property-decorator";
import CamiloDetail from "@/components/CamiloDetail.vue";
import SyntheticDialog from "@/components/SyntheticDialog.vue";
import CamiloCode from "@/components/CamiloCode.vue";
import { getModule } from "vuex-module-decorators";
import GlobalStateStore from "@/store/modules/global-state.store";
import { IVoucherDetailV2, VoucherApi } from "@/api/voucher.api";
import { Skeleton, Popup } from "vant";
import { path } from "animejs";
import { observer } from "@/utils/common.util";
import InteractionLib from "@/utils/interaction.lib";

@Component({
  components: {
    SyntheticDialog,
    CamiloCode,
    CamiloDetail,
    Skeleton,
    Popup,
  },
})
export default class MyVoucherDetail extends Vue {
  @Ref("CamiloCode")
  private readonly CamiloCodeRef!: CamiloCode;
  id = Number(this.$route.params.id);
  globalState = getModule(GlobalStateStore);
  skeleton = true;
  voucher: IVoucherDetailV2 | null = null;
  dialog = false;
  agignPop = false;

  get flutter() {
    return InteractionLib.isApp();
  }
  againHandler() {
    this.agignPop = true;
  }
  confirmHandler(){
    this.CamiloCodeRef.confirmHandler()
    this.agignPop = false
  }
  get isLogin() {
    return Boolean(this.globalState.userToken);
  }

  async handleRefreshData(): Promise<void> {
    this.skeleton = true;
    this.voucher = await VoucherApi.getMyVoucherDetailV2(this.id);
    if (this.voucher.code_is_seal != 0) {
      document.title = "我的补给";
    }
    this.skeleton = false;
  }
  mounted(): void {
    observer("refreshInit", () => this.handleRefreshData());
    InteractionLib.screenshotListen();
    this.handleRefreshData();
  }
  toShopDetail() {
    this.$router.push({ path: `/sp-store/${this.voucher?.product_id}` });
  }
}
